import * as React from "react"
import { StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import BannerComponent from "../components/banner-component"
import "../styles/banner.css"
import "../styles/partners.css"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

import shapeone from "../images/design-element-one.svg"
import shapetwo from "../images/design-element-two.svg"
import shapethree from "../images/design-element-three.svg"
import shapefour from "../images/design-element-four.svg"

import shapemobone from "../images/design-element-four-mob.svg"
import shapemobtwo from "../images/design-element-three-mob.svg"
import shapemobthree from "../images/design-element-two-mob.svg"
import shapemobfour from "../images/design-element-one-mob.svg"

const ThankYou = ({ data }) => {
  const recentpost = data.recent
  return (
    <>
      {data.allWpPage.edges.map(({ node }) => (
        <Helmet>
          <title>{node.title} | Position2</title>
          <meta name="description" content={node.seo.metaDesc} />
          <body className={node.slug} />
        </Helmet>
      ))}

      <Layout>
        {data.allWpPage.edges.map(({ node }) => (
          <section id="Banner">
            <div className="banner-section">
              <picture>
                <source
                  media="(max-width: 991.98px)"
                  srcSet={node.bannerSection.mobileBanner.node.localFile.publicURL}
                  alt={node.bannerSection.mobileBanner.node.altText}
                />
                <img
                  src={node.bannerSection.heroBannerImage.node.localFile.publicURL}
                  alt={node.bannerSection.heroBannerImage.node.altText}
                />
              </picture>
              <div className="banner-holder">
                <div className="container">
                  <div className="images dekstop">
                    <div className="img-one image-shape">
                      <img src={shapeone} alt=" Keep on Growing One" />
                    </div>
                    <div className="img-two image-shape">
                      <img src={shapetwo} alt="Keep on Growing Two" />
                    </div>
                    <div className="img-three image-shape">
                      <img src={shapethree} alt="Keep on Growing Three" />
                    </div>
                    <div className="img-four image-shape">
                      <img src={shapefour} alt="Keep on Growing Four" />
                    </div>
                    <div className="title image-shape">
                      <h1>{node.title}</h1>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: node.bannerSection.bannerDescription,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="images mobile">
                    <div className="image-shape mobile-img-four">
                      <div className="img-section">
                        <img src={shapemobone} alt="Keep on Growing Four" />
                      </div>
                      <div className="text">
                        <h1>{node.title}</h1>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: node.bannerSection.bannerDescription,
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="mobile-img-three image-shape">
                      <img src={shapemobtwo} alt="Keep on Growing Three" />
                    </div>
                    <div className="mobile-img-two image-shape">
                      <img src={shapemobthree} alt="Keep on Growing Two" />
                    </div>
                    <div className="mobile-img-one image-shape">
                      <img src={shapemobfour} alt=" Keep on Growing One" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ))}
        <section id="RecentPost">
          <div className="container">
              <h2>Recent Posts</h2>
          </div>
        </section>
        <section className="blog-overview">
          <div className="container">
            <div className="clearfix">
              {recentpost.nodes.map(
                ({ uri, blogSection, date, title, author }) => (
                  <a href={uri} className="each-blog">
                    <div className="wrap-img">
                      <img
                        src={blogSection.blogFeaturedImage.node.localFile.publicURL}
                        alt={blogSection.blogFeaturedImage.node.altText}
                      />
                    </div>
                    <div className="blog-wrap">
                      <p className="date">{date}</p>
                      <h2>{title}</h2>
                      <div className="author">
                        <span>By</span> {author.node.name}
                      </div>
                      <div className="blog-descp">
                        <span>Read More</span>
                      </div>
                    </div>
                  </a>
                )
              )}
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}
export default function ThankYouBlock(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWpPage(filter: {id: {eq: "cG9zdDoxMTE0NQ=="}}) {
    edges {
      node {
        title
        id
        slug
        seo {
          metaDesc
        }
        bannerSection {
          bannerCta
          bannerCtaLink
          bannerDescription
          bannerTitle
          heroBannerImage {
            node {
              altText
              localFile {
                publicURL
              }
            }
          }
          mobileBanner {
            node {
              altText
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
  recent: allWpPost(limit: 3, sort: {order: DESC, fields: date}) {
    nodes {
      title
      uri
      date(formatString: "yy")
      blogSection {
        topBannerImage {
          node {
            altText
            localFile {
              publicURL
            }
          }
        }
        blogFeaturedImage {
          node {
            altText
            localFile {
              publicURL
            }
          }
        }
      }
      author {
        node {
          uri
          name
        }
      }
    }
  }
        }
      `}
      render={data => <ThankYou data={data} {...props} />}
    />
  )
}
ThankYou.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}
